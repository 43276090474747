<template>
  <div class="rates">
    <div class="industry-second">
      <div class="industry-second-up">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          @submit="handleSearch"
        >
          <a-row :gutter="24">
            <a-col :span="5">
              <!-- <a-form-item label="行政区划：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChangeArea"
                  v-model="param.selectedAreaId"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="(item, index) in xzqh"
                    :key="index"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item> -->
              <a-form-item label="行政区划：">
                <a-cascader
                  :options="options"
                  @change="areaChange"
                  placeholder="请选择"
                  expandTrigger="hover"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="年度：">
                <a-select
                  mode="single"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.reportYear"
                  @change="handleChange"
                >
                  <a-select-option v-for="i in year" :key="i">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="水源类型：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  v-model="param.waterType"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in waterTypes"
                    :key="index"
                    @change="handleChangeWaterType"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="工程运行管理单位：">
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择"
                  @change="handleChange"
                  v-model="param.natManageUnit"
                >
                  <a-select-option
                    :value="item.value"
                    v-for="(item, index) in natManageUnits"
                    :key="index"
                    @change="handleChangeNatManageUnit"
                  >
                    {{ item.description }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :span="4"
              :style="{ textAlign: 'right' }"
              style="margin-top: 5px;"
            >
              <a-button type="primary" html-type="submit">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清除
              </a-button>
            </a-col>
          </a-row>
          <!--<a-row>
            <a-col :span="24">
              <div class="industry-second-up-select">
                <div class="industry-second-up-select-l">
                  工程运行管理单位：
                </div>
                <div class="industry-second-up-select-r">
                  <div
                    v-for="(item, index) in tabListd"
                    :key="index"
                    :class="
                      projectIndex.indexOf(item) !== -1 ? 'select-rd' : ''
                    "
                    @click="checkProject(item)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>-->
          <a-row> </a-row>
        </a-form>
      </div>
    </div>
    <div class="industry-six">
      <!-- 四率 -->
      <div class="industry-six-l" v-if="rateCount == 4 || rateCount == 5">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">四率</div>
          </div>
        </div>
        <div class="fourrates">
          <div class="fourrates-p">
            <div class="fourrates-one">
              <div class="text textAllA">
                <div class="text1">{{ rateData[0].name }}</div>
                <div class="text2">{{ rateData[0].value }}%</div>
              </div>
              <div class="progress">
                <a-progress
                  type="circle"
                  :strokeWidth="8"
                  strokeColor="#ea9a56"
                  :percent="rateData[0].value"
                  :width="105"
                  :show-info="false"
                />
              </div>
            </div>
            <div class="fourrates-one">
              <div class="text textAllB">
                <div class="text1">{{ rateData[1].name }}</div>
                <div class="text2">{{ rateData[1].value }}%</div>
              </div>
              <div class="progress">
                <a-progress
                  type="circle"
                  :strokeWidth="8"
                  strokeColor="#a2b966"
                  :percent="rateData[1].value"
                  :width="105"
                  :show-info="false"
                />
              </div>
            </div>
          </div>
          <div class="fourrates-p">
            <!-- <div class="fourrates-one">
              <div class="text textAllC" v-if="rateData[2]">
                <div class="text1">{{ rateData[2].name }}</div>
                <div class="text2">{{ rateData[2].value }}%</div>
              </div>
              <div class="progress">
                <a-progress
                  type="circle"
                  :v-if="8"
                  strokeColor="#5981b8"
                  :percent="rateData[2].value"
                  :width="105"
                  :show-info="false"
                />
              </div>
            </div> -->
            <div class="fourrates-one">
              <div class="text textAllC" v-if="rateData[2]">
                <div class="text1">{{ rateData[2].name }}</div>
                <div class="text2">{{ rateData[2].value }}%</div>
              </div>
              <div class="progress">
                <a-progress
                  type="circle"
                  :strokeWidth="8"
                  strokeColor="#5981b8"
                  :percent="rateData[2].value"
                  :width="105"
                  :show-info="false"
                />
              </div>
            </div>
            <div class="fourrates-one">
              <div class="text textAllD" v-if="rateData[3]">
                <div class="text1">{{ rateData[3].name }}</div>
                <div class="text2">{{ rateData[3].value }}%</div>
              </div>
              <div class="progress">
                <a-progress
                  type="circle"
                  :strokeWidth="8"
                  strokeColor="#95b4db"
                  :percent="rateData[3].value"
                  :width="105"
                  :show-info="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 三率 -->
      <div v-if="rateCount == 3" class="industry-six-l">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">三率</div>
          </div>
        </div>
        <div class="fourrates-three">
          <div class="fourrates-p">
            <div
              class="fourrates-one"
              v-for="(item, index) in rateData"
              :key="index"
            >
              <div class="progress">
                <a-progress
                  type="circle"
                  :strokeWidth="8"
                  :strokeColor="
                    item.name == '集中供水率'
                      ? '#ea9a56'
                      : item.name == '供水保证率'
                      ? ' #a2b966'
                      : item.name == '自来水普及率'
                      ? ' #5981b8'
                      : ' #95b4db'
                  "
                  :percent="item.value"
                  :width="105"
                  :show-info="false"
                />
              </div>
              <div
                class="text"
                :class="
                  item.name == '集中供水率'
                    ? 'textAllA'
                    : item.name == '供水保证率'
                    ? 'textAllB'
                    : item.name == '自来水普及率'
                    ? 'textAllC'
                    : 'textAllD'
                "
              >
                <div class="text1">{{ item.name }}</div>
                <div class="text2">{{ item.value }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 二率 -->
      <div v-if="rateCount == 2" class="industry-six-l">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">二率</div>
          </div>
        </div>
        <div class="fourrates-two">
          <div class="fourrates-p">
            <div
              class="fourrates-one"
              v-for="(item, index) in rateData"
              :key="index"
            >
              <div class="progress">
                <a-progress
                  type="circle"
                  :strokeWidth="8"
                  :strokeColor="
                    item.name == '集中供水率'
                      ? '#ea9a56'
                      : item.name == '供水保证率'
                      ? ' #a2b966'
                      : item.name == '自来水普及率'
                      ? ' #5981b8'
                      : ' #95b4db'
                  "
                  :percent="item.value"
                  :width="105"
                  :show-info="false"
                />
              </div>
              <div
                class="text"
                :class="
                  item.name == '集中供水率'
                    ? 'textAllA'
                    : item.name == '供水保证率'
                    ? 'textAllB'
                    : item.name == '自来水普及率'
                    ? 'textAllC'
                    : 'textAllD'
                "
              >
                <div class="text1">{{ item.name }}</div>
                <div class="text2">{{ item.value }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="industry-six-r">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="flex align-center">
              <div class="line"></div>
              <div class="title">{{ tabName }}对比</div>
            </div>
            <div class="industry-thirdone-title-r">
              <div style="display: flex;">
                <div
                  class="industry-thirdone-title-rdone"
                  :class="
                    tabListeIndex1 == index
                      ? 'industry-thirdone-title-rselect'
                      : ''
                  "
                  v-for="(item, index) in tabListe"
                  :key="index"
                  @click="tabListeCheck1(index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="industry-thirdone-title-r-con">
          <div class="industry-thirdone-title-r">
            <!-- <div style="margin-right:50px;display: flex;">
              <div
                class="industry-thirdone-title-rdone"
                :class="
                  tabListeIndex1 == index
                    ? 'industry-thirdone-title-rselect'
                    : ''
                "
                v-for="(item, index) in tabListe"
                :key="index"
                @click="tabListeCheck1(index)"
              >
                {{ item }}
              </div>
            </div> -->

            <div class="industry-progress-tab">
              <div
                class="industry-progress-tabone"
                :class="tabListeIndex2 == index ? 'progress-tabone-select' : ''"
                v-for="(item, index) in tabList"
                :key="index"
                @click="waterProject3(index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>

        <div style="position: relative;width:100%;height:90%">
          <div id="tuline3" style="position: relative;width:100%;height:100%">
            <div class="linesort">
              <img
                v-if="sortup1"
                src="@/assets/img/industry/sortud.png"
                @click="sort1()"
                alt=""
              />
              <img
                v-if="!sortup1"
                src="@/assets/img/industry/sortup.png"
                @click="sort1()"
                alt=""
              />
            </div>
            <div id="line4" style="width:100%;height:100%;"></div>
          </div>
          <div id="tableline3" style="margin-top:20px">
            <a-table
              :columns="columns"
              :data-source="data"
              bordered
              :pagination="false"
              size="middle"
              :scroll="{ x: 'calc(250px + 50%)', y: 280 }"
            />
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="rates-one" v-if="hasQualityPow">
      <div class="rates-one-l">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">水质达标率</div>
          </div>
        </div>
        <!-- 地图 -->
        <div class="rates-onemap-con">
          <!--<div class="legend">-->
            <!--<div class="legendone">-->
              <!--<div class="block" style="background: #E0EFE3;"></div>-->
              <!--<div class="text">90%以上</div>-->
            <!--</div>-->
            <!--<div class="legendone">-->
              <!--<div class="block" style="background: #c9d9cc;"></div>-->
              <!--<div class="text">80%-90%</div>-->
            <!--</div>-->
            <!--<div class="legendone">-->
              <!--<div class="block" style="background: #9eb5a3;"></div>-->
              <!--<div class="text">70%-80%</div>-->
            <!--</div>-->
            <!--<div class="legendone">-->
              <!--<div class="block" style="background: #7b9884;"></div>-->
              <!--<div class="text">60%-70%</div>-->
            <!--</div>-->
            <!--<div class="legendone">-->
              <!--<div class="block" style="background: #6a8974;"></div>-->
              <!--<div class="text">50%-60%</div>-->
            <!--</div>-->
            <!--<div class="legendone">-->
              <!--<div class="block" style="background: #2B6041;"></div>-->
              <!--<div class="text">50%以下</div>-->
            <!--</div>-->
          <!--</div>-->
          <!-- <div class="title">{{ province }}</div> -->
          <div
            class="map"
            id="initChinaMap"
            style="height:100%;width:95%;position: absolute;top:0px;left:20px;"
          ></div>
        </div>
      </div>
      <div class="rates-one-r">
        <div class="industry-thirdone-title">
          <div class="industry-thirdone-title-l">
            <div class="line"></div>
            <div class="title">水质达标率列表</div>
          </div>
        </div>
        <div id="tableline3" style="margin-top:20px">
          <a-table
            style="text-align: center;"
            :columns="columns1"
            :data-source="data1"
            :pagination="false"
            bordered
            size="middle"
            :scroll="{ x: 'calc(50%)', y: 520 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAreas, getDefaultArea } from "@/api/area/index";
import { baseMenuList } from "@/api/industry/index";
import { getIndustryRates, getIndustryAreaPows } from "@/api/industry/rate";
import { getAreaTreeList } from "@/api/basic/common";
import {
  getConsumAreaBadQuality,
} from "@/api/water/analysis";
import axios from "axios";

// 表格1数据
const columns = [
  // {
  //   title: "省（自治区、直辖市）市、县级名称",
  //   dataIndex: "name",
  //   key: "name",
  //   width: 100
  //   // fixed: 'left',
  // },
  // {
  //   title: "集中供水率",
  //   dataIndex: "percent1",
  //   key: "percent1",
  //   width: 60,
  //   sorter: (a, b) => a.percent1 - b.percent1
  // },
  // {
  //   title: "自来水普及率 ",
  //   dataIndex: "percent2",
  //   key: "percent2",
  //   width: 60,
  //   sorter: (a, b) => a.percent2 - b.percent2
  // },
  // {
  //   title: "供水保证率 ",
  //   dataIndex: "percent3",
  //   key: "percent3",
  //   width: 60,
  //   sorter: (a, b) => a.percent3 - b.percent3
  // },
  // {
  //   title: "规模化供水率",
  //   dataIndex: "percent4",
  //   key: "percent4",
  //   width: 60,
  //   sorter: (a, b) => a.percent4 - b.percent4
  // }
];
// 表格1
const data = [];

// 表格2数据
const columns1 = [
  {
    title: "序列",
    dataIndex: "key",
    key: "key",
    width: 80,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "城市名称",
    dataIndex: "name",
    key: "name",
    width: 100,
    align: "center"
    // fixed: 'left',
  },
  {
    title: "水质达标率",
    dataIndex: "percent1",
    key: "percent1",
    width: 80,
    align: "center",
    sorter: (a, b) => a.percent1 - b.percent1
  }
];
// 表格2
const data1 = [
  // {
  //   key: 1,
  //   name: "太原",
  //   percent1: 1
  // },
  // {
  //   key: 2,
  //   name: "运城",
  //   percent1: 2
  // },
  // {
  //   key: 3,
  //   name: "临汾",
  //   percent1: 1
  // },
  // {
  //   key: 4,
  //   name: "长治",
  //   percent1: 1
  // },
  // {
  //   key: 5,
  //   name: "阳泉",
  //   percent1: 1
  // },
  // {
  //   key: 6,
  //   name: "大同",
  //   percent1: 1
  // },
  // {
  //   key: 7,
  //   name: "晋城",
  //   percent1: 1
  // },
  // {
  //   key: 8,
  //   name: "忻州",
  //   percent1: 1
  // },
  // {
  //   key: 9,
  //   name: "晋中",
  //   percent1: 1
  // },
  // {
  //   key: 10,
  //   name: "朔州",
  //   percent1: 1
  // },
  // {
  //   key: 11,
  //   name: "吕梁",
  //   percent1: 1
  // }
];
const map_data = [
  // {name: '太原市', value: 5},
  // {name: '吕梁市', value: 10},
  // {name: '临汾市', value: 15},
  // {name: '大同市', value: 20},
  // {name: '长治市', value: 40},
  // {name: '阳泉市', value: 50},
  // {name: '运城市', value: 60},
  // {name: '忻州市', value: 70},
  // {name: '朔州市', value: 80},
  // {name: '晋城市', value: 90},
  // {name: '晋中市', value: 100},
];
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      xzqh: ["江苏省", "北京市", "山西省"], //测试数据/行政区划
      year: [], //测试数据/年度
      waterTypes: [],
      natManageUnits: [],
      options: [
        {
          value: "1",
          label: "山西省"
        }
      ], //行政区划
      oriAreaId: undefined,
      queryName: "全省",
      // 筛选条件
      param: {
        defaultAreaId: undefined,
        selectedAreaId: undefined,
        areaId: undefined,
        reportYear: undefined,
        waterType: undefined,
        natManageUnit: undefined,
        areaName: ""
      },
      sylx: ["地表水", "地下水"], //测试数据/水源类型
      tabList: [], //集中供水率tab
      tabName: "",
      tabListd: [
        "全部",
        "县级水务局",
        "县级供水管理总站",
        "片区或流域等县级派生水管站（所）",
        "乡镇政府或水管站",
        "国有公司",
        "自来水公司",
        "股份制公司",
        "民营公司",
        "外资公司",
        "私人租赁或承包",
        "供水协会",
        "合作组织或用水户协会",
        "村委会",
        "其他"
      ], //工程运行管理单位
      projectIndex: [], //切换工程运行管理单位index
      // 统计数据
      rate: {
        focusWasePow: 0, //集中供水率
        scalePow: 0,
        endPow: 0,
        wawPow: 0,
        qualityPow: 0,
        plaWasuRat: 0,
        popWasu: 0,
        plaCwse: 0,
        peoCwse: 0,
        plaCwseRat: 0,
        plaDwse: 0,
        peoDwse: 0,
        plaDwseRat: 0,
        waterList: [],
        waterRat: 0,
        consumProjects: [],
        tableData: []
      },
      rateCount: 0,
      rateData: [],
      hasQualityPow: false,
      tabListe: ["图", "表格"], //集中供水率对比tab
      tabListeIndex: 0, //切换集中供水率对比index
      tabListeIndex1: 0, //集中供水率对比 图表index
      tabListeIndex2: 0, //集中供水率对比 分类index
      sortup1: false, //集中供水率对比 排序
      area_pows: [],
      area_pows1: [],
      columns,
      data,
      data1,
      columns1,
      map_data,
      province: "山西", //当前选择的省
      listParam: {
        areaIds: "1",
        name: "",
        natManageUnits: "",
        waterType: "0",
        wasuScales: "0",
        reportYear: "2020",
        updateMode: "0",
        pageSize: 5,
        pageNumber: 1
      },
      userAreaId: {
        id: 1
      },
      code: null,//当前选中区域地图ID
      level: 1
    };
  },
  created() {
    var date = new Date();

    this.listParam.reportYear = date.getFullYear();
    this.year.unshift(date.getFullYear() - 1);
    for (let i = 0; i < 9; i++) {
      this.year = this.year.concat(date.getFullYear() + i);
    }
    let area = JSON.parse(localStorage.getItem("areaValue"));
    this.userAreaId.id = area.areaId;
    getAreaTreeList(this.userAreaId).then(response => {
      this.options = response.data.list;
      this.code = response.data.list[0].code
      this.level = response.data.list[0].level
    });
    this.getAreaList();
  },
  mounted() {
    // this.$chart.line4("line4", this.tabListeIndex2);
    // this.$chart.initChinaMap("initChinaMap", this.map_data);

    document.getElementById("tableline3").style.display = "none";
  },
  methods: {
    areaChange(value,selectedOptions) {
      this.listParam.areaIds = value[value.length - 1];
      this.param.areaId = value[value.length - 1];
      this.code = selectedOptions[value.length - 1].code
      this.level = selectedOptions[value.length - 1].level
      console.log(value);
      console.log(selectedOptions);
    },
    async initData() {
      await baseMenuList({
        menu: "水源类型"
      }).then(res => {
        // console.log('水源类型')
        // console.log(res)
        this.waterTypes = res.data.list;
      });
      await baseMenuList({
        menu: "工程运行管理单位性质"
      }).then(res => {
        // console.log('工程运行管理单位')
        // console.log(res)
        this.natManageUnits = res.data.list;
      });
      // 查询相关数据
      this.getRates();

      // this.getTableData();
    },
    getAreaList() {
      let data = getDefaultArea();
      console.log(data);
      this.param.defaultAreaId = data.id;
      this.param.areaName = data.name;
      this.param.reportYear = 2020;

      if (!this.param.areaId) {
        this.param.areaId = this.param.defaultAreaId;
      }

      getAreas({
        id: this.param.areaId
      }).then(res => {
        if (res.code == 200) {
          this.xzqh = res.data.list;
        }
        this.initData();
      });
    },
    // 切换工程运行管理单位
    checkProject(item) {
      if (this.projectIndex.indexOf(item) == -1) {
        this.projectIndex.push(item);
      } else {
        this.projectIndex.splice(
          this.projectIndex.findIndex(i => i === item),
          1
        );
      }
    },
    // 切换集中供水率对比：表格和图表
    tabListeCheck1(index) {
      this.tabListeIndex1 = index;
      if (index === 0) {
        document.getElementById("tuline3").style.display = "block";
        document.getElementById("tableline3").style.display = "none";
        let datas = this.handleConsumpRrojects(this.tabListeIndex2);
        this.$chart.line4("line4", this.tabListeIndex2, datas);
      } else {
        document.getElementById("tableline3").style.display = "block";
        document.getElementById("tuline3").style.display = "none";
        this.handleConsumpRrojects(1);
      }
    },
    // 点击集中供水率对比模块
    waterProject3(index) {
      this.tabListeIndex2 = index;
      let datas = this.handleConsumpRrojects(index);
      //console.log(datas);
      this.tabName = this.tabList[index].name;
      this.$chart.line4("line4", this.tabListeIndex2, datas);
    },
    // 集中供水率对比/排序
    sort1() {
      // console.log(this.sortup1);
      this.sortup1 = !this.sortup1;
      let datas = this.handleConsumpRrojects(this.tabListeIndex2);
      this.$chart.line4("line4", this.tabListeIndex2, datas);
    },
    //查询
    handleSearch(e) {
      e.preventDefault();
      this.getRates();
    },
    //清除
    handleReset() {
      this.param.areaId = this.param.defaultAreaId;
      this.param.selectedAreaId = undefined;
      this.param.reportYear = 2020;
      this.param.waterType = undefined;
      this.param.natManageUnit = undefined;
      // this.form.reset()
      this.initData();
    },
    //多选运行管理单位
    handleChange(value) {
      this.param.reportYear = value;
      console.log(`selected ${value}`);
    },
    // 统计数据
    getRates() {
      getIndustryRates({
        areaId: this.param.areaId,
        reportYear: this.param.reportYear,
        waterTypes: this.param.waterType,
        natManageUnits: this.param.natManageUnit
      }).then(res => {
        if (res.code == 0) {
          let data_permissions = JSON.parse(
            localStorage.getItem("data_permissions")
          );
          let admin = localStorage.getItem("admin");
          this.rateData = [];
          res.data.wawPow = 98
          res.data.scalePow = 100
          if (admin == "1") {
            this.rateCount = 4;
            this.hasQualityPow = true;
            this.tabList = [
              {
                name: "集中供水率",
                value: "focusWasePow"
              },
              {
                name: "自来水普及率",
                value: "wawPow"
              },
              {
                name: "规模化供水率",
                value: "scalePow"
              },
              {
                name: "供水保证率",
                value: "endPow"
              }
            ];
            this.rateData = [
              {
                name: "集中供水率",
                value: Number(res.data.focusWasePow)
              },
              {
                name: "供水保证率",
                value: Number(res.data.endPow)
              },
              {
                name: "自来水普及率",
                value: Number(res.data.wawPow)
              },
              {
                name: "规模化供水率",
                value: Number(res.data.scalePow)
              },
              {
                name: "水质达标率",
                value: Number(res.data.qualityPow)
              }
            ];
            // 四率
            this.rate.focusWasePow = Number(res.data.focusWasePow);
            this.rate.scalePow = Number(res.data.scalePow);
            this.rate.endPow = Number(res.data.endPow);
            this.rate.wawPow = Number(res.data.wawPow);
            this.rate.qualityPow = Number(res.data.qualityPow);
          } else {
            // 四率（五率权限）
            let rate = data_permissions[0].children;
            this.tabList = [];
            if (rate) {
              let k = 0;
              for (let i in rate) {
                let column = rate[i].column;
                if (column == "qualityPow") {
                  this.hasQualityPow = true;
                }
                if (rate[i].checked && column != "qualityPow") {
                  k++;
                  this.rateData.push({
                    name: rate[i].name,
                    value: res.data[column] || 0
                  });
                  this.tabList.push({
                    name: rate[i].name,
                    value: rate[i].column
                  });
                }
              }
              this.rateCount = k;
            }
          }
          console.log('this.rateData',this.rateData)
          for (let i = 0; i < this.rateData.length; i++) {
            this.rateData[i].value = Number(this.rateData[i].value)
          }
          this.tabName = this.tabList[this.tabListeIndex2].name;
        }
        this.getAreaPows();
      });
    },
    // 集中供水率对比
    getAreaPows() {
      getIndustryAreaPows({
        areaId: this.param.areaId,
        reportYear: this.param.reportYear,
        waterTypes: this.param.waterType,
        natManageUnits: this.param.natManageUnit
      }).then(res => {
        if (res.code == 200 || res.code == 0) {
          this.area_pows = res.data.list;
          let datas = this.handleConsumpRrojects(0);
          // console.log(datas);
          // 渲染图表
          this.$chart.line4("line4", this.tabListeIndex2, datas);

          // 渲染地图
          // this.$chart.initChinaMap(
          //   "initChinaMap",
          //   this.province,
          //   this.map_data
          // );
          if(this.code){
            getConsumAreaBadQuality({
              areaIds: this.param.areaId,
              reportYear: this.param.reportYear
            }).then(res => {
              if (res.code == 200 || res.code == 0) {
                let result = Object.assign([],this.map_data)
                let len1 = result.length
                let list = res.data.list
                let len2 = list.length
                for (let i = 0; i < len1; i++) {
                  for (let j = 0; j < len2; j++) {
                    if(result[i].name === list[j].adName){
                      result[i].detectName = list[j].detectName
                      break
                    }
                  }
                }
                console.log('result',result)
                let code = this.code.substring(0,4) + '00'
                if(this.level == 1 || this.level == 2){
                  // code += '_full'
                }
                console.log('code',code)
                let url = `https://www.etspatial.com/download/border/${code}.geojson`
                // let url = `https://geo.datav.aliyun.com/areas_v2/bound/${code}.json`
                axios({
                  url:url,
                  method:'get'
                }).then(res =>{
                  console.log('this.map_data',this.map_data)
                  this.$chart.initGeojson("initChinaMap",res.data,result)
                }).catch(err => {
                  console.log(err)
                })
              }
            })
          }
        }
      });
    },
    handleChangeArea(value) {
      this.param.areaId = value;
      this.param.selectedAreaId = value;
    },
    handleChangeWaterType(value) {
      this.param.waterType = value;
    },
    handleChangeNatManageUnit(value) {
      this.param.natManageUnit = value;
    },
    sortByNumber(a, b) {
      if (this.sortup1) {
        if (this.tabListeIndex2 == 0) {
          return Number(b.focusWasePow) * 100 - Number(a.focusWasePow) * 100;
        }
        if (this.tabListeIndex2 == 1) {
          return Number(b.wawPow) * 100 - Number(a.wawPow) * 100;
        }
        if (this.tabListeIndex2 == 2) {
          return Number(b.scalePow) * 100 - Number(a.scalePow) * 100;
        }
        if (this.tabListeIndex2 == 3) {
          return Number(b.endPow) * 100 - Number(a.endPow) * 100;
        }
      } else {
        if (this.tabListeIndex2 == 0) {
          return Number(a.focusWasePow) * 100 - Number(b.focusWasePow) * 100;
        }
        if (this.tabListeIndex2 == 1) {
          return Number(a.wawPow) * 100 - Number(b.wawPow) * 100;
        }
        if (this.tabListeIndex2 == 2) {
          return Number(a.scalePow) * 100 - Number(b.scalePow) * 100;
        }
        if (this.tabListeIndex2 == 3) {
          return Number(a.endPow) * 100 - Number(b.endPow) * 100;
        }
      }
    },
    // 全省农村供水工程分类
    handleConsumpRrojects(index) {
      // alert("tabListeIndex1:" + this.tabListeIndex1 + "-index:" + index);
      let title = [];
      let data = [];
      this.data = [];
      this.data1 = [];
      this.map_data = [];
      if (this.tabListeIndex1 == 0) {
        this.area_pows.sort(this.sortByNumber);
      }
      for (let i in this.area_pows) {
        let result = this.area_pows[i];
        result.wawPow = 98
        result.scalePow = 100
        // 渲染地图数据
        this.map_data.push({
          name: result.adName,
          value: result.qualityPow
        });

        // 水质达标率
        this.data1.push({
          key: parseInt(i) + 1,
          name: result.adName,
          percent1: result.qualityPow
        });

        // 渲染集中供水率对比
        title.push(result.adName);
        let data_permissions = JSON.parse(
          localStorage.getItem("data_permissions")
        );
        let admin = localStorage.getItem("admin");
        if (admin == "0") {
          let rate = data_permissions[0];

          if (this.tabListeIndex1 == 0) {
            for (let i in rate.children) {
              if (
                rate.children[i].column != "qualityPow" &&
                rate.children[i].checked &&
                rate.children[i].column ==
                  this.tabList[this.tabListeIndex2].value
              ) {
                data.push({
                  value: result[rate.children[i].column],
                  name: result.adName
                });
              }
            }
          } else {
            this.columns = [
              {
                title: "序号",
                dataIndex: "num",
                key: "num",
                width: 50,
                align: "center"
                // fixed: 'left',
              },
              {
                title: "省（自治区、直辖市）市、县级名称",
                dataIndex: "name",
                key: "name",
                width: 100,
                align: "center"
                // fixed: 'left',
              }
            ];
            let data = {
              key: i,
              num: Number(i) + 1,
              name: result.adName
            };
            for (let k in rate.children) {
              let result1 = rate.children[k];
              if (
                result1 &&
                result1.column != "qualityPow" &&
                result1.checked &&
                result1.column == this.tabList[this.tabListeIndex2].value
              ) {
                this.columns.push({
                  title: result1.name,
                  dataIndex: result1.column,
                  key: result1.column,
                  num: Number(result1.column) + 1,
                  width: 60,
                  align: "center",
                  sorter: (a, b) => a.percent2 - b.percent2
                });

                let column = result1.column;
                data[column] = result[column];
              }
            }
            this.data.push(data);
            console.log(1111111111);
            console.log(this.data);
          }
        } else {
          // 如果是图表
          if (this.tabListeIndex1 == 0) {
            switch (index) {
              case 0: // 集中供水率
                data.push({
                  value: result.focusWasePow,
                  name: result.adName
                });
                break;
              case 1: // 自来水普及率
                data.push({
                  value: result.wawPow,
                  name: result.adName
                });
                break;
              case 2: // 规模化供水率
                data.push({
                  value: result.scalePow,
                  name: result.adName
                });
                break;
              case 3: // 供水保证率
                data.push({
                  value: result.endPow,
                  name: result.adName
                });
                break;
            }
          } else {
            // 如果是表格
            this.columns = [
              {
                title: "序号",
                dataIndex: "num",
                key: "num",
                width: 50,
                align: "center"
                // fixed: 'left',
              },
              {
                title: "省（自治区、直辖市）市、县级名称",
                dataIndex: "name",
                key: "name",
                width: 100,
                align: "center"
                // fixed: 'left',
              },
              {
                title: "集中供水率",
                dataIndex: "focusWasePow",
                key: "focusWasePow",
                width: 100,
                align: "center",
                sorter: (a, b) => a.focusWasePow - b.focusWasePow
              },
              {
                title: "自来水普及率",
                dataIndex: "wawPow",
                key: "wawPow",
                width: 100,
                align: "center",
                sorter: (a, b) => a.wawPow - b.wawPow
              },
              {
                title: "规模化供水率",
                dataIndex: "scalePow",
                key: "scalePow",
                width: 100,
                align: "center",
                sorter: (a, b) => a.scalePow - b.scalePow
              },
              {
                title: "供水保证率",
                dataIndex: "endPow",
                key: "endPow",
                width: 100,
                align: "center",
                sorter: (a, b) => a.endPow - b.endPow
              }
            ];
            this.data.push({
              num: Number(i) + 1,
              key: i,
              name: result.adName,
              focusWasePow: result.focusWasePow,
              wawPow: result.wawPow,
              endPow: result.endPow,
              scalePow: result.scalePow
            });
          }
        }
      }

      return {
        title: title,
        data: data
      };
    }
  }
};
</script>

<style scoped lang="less">
#tableline3 /deep/ .ant-table-body {
  overflow-x: hidden !important;
}
.textAllA {
  color: #ea9a56;
}
.textAllB {
  color: #a2b966;
}
.textAllC {
  color: #5981b8;
}
.textAllD {
  color: #95b4db;
}
.industry-second {
  width: 100%;
  background: #ffffff;
  .industry-second-up {
    padding: 30px 20px 15px 20px;
    border-bottom: 1px solid #e1e1e1;
  }
  .industry-second-ud {
    display: flex;
    padding: 20px 0 20px 0;
    .industry-second-udone {
      position: relative;
      cursor: pointer;
      width: 105px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-second-up-select {
    display: flex;
    align-items: flex-start;
    .industry-second-up-select-l {
      width: 200px;
      height: 40px;
      line-height: 40px;
    }
    .industry-second-up-select-r {
      display: flex;
      flex-wrap: wrap;
      div {
        height: 40px;
        margin-right: 10px;
        padding: 0 15px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .select-rd {
        color: #ffffff;
        background: #1890ff;
      }
    }
  }
}
.industry-six {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .industry-six-l {
    width: 37%;
    min-height: 400px;
    background: #ffffff;
    padding: 20px;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
    .fourrates {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 20px;
      .fourrates-p {
        display: flex;
        width: 100%;
        justify-content: center;
      }
      .fourrates-one:last-child {
        margin-left: 60px;
      }
      .fourrates-one {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        margin-top: 20px;
        flex-wrap: wrap;
        margin-left: 40px;
        .text {
          width: 100px;
          .text1 {
            font-size: 14px;
          }
          .text2 {
            font-size: 20px;
          }
        }
        .progress {
        }
      }
    }
    .fourrates-three {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .fourrates-p {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .fourrates-one {
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 40px;
        text-align: center;
        margin-top: 50px;
        .text {
          margin: 0 auto;
          margin-top: 30px;
          .text1 {
            font-size: 14px;
          }
          .text2 {
            font-size: 20px;
          }
        }
        .progress {
        }
      }
    }
    .fourrates-two {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .fourrates-p {
        display: flex;
        width: 100%;
        justify-content: space-around;
      }
      .fourrates-one {
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 40px;
        text-align: center;
        margin-top: 50px;
        .text {
          margin: 0 auto;
          margin-top: 30px;
          .text1 {
            font-size: 14px;
          }
          .text2 {
            font-size: 20px;
          }
        }
        .progress {
        }
      }
    }
  }
  .industry-six-r {
    width: 61.5%;
    min-height: 400px;
    background: #ffffff;
    padding: 20px 20px 1px;
    .industry-thirdone-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .industry-thirdone-title-l {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
      .industry-thirdone-title-r {
        display: flex;
        cursor: pointer;
        .industry-progress-tab {
          width: 100%;
          display: flex;
          font-size: 12px;
          justify-content: space-between;
          // margin-top: 4px;
          .industry-progress-tabone {
            position: relative;
            cursor: pointer;
            margin-left: 34px;
            font-size: 14px;
            div {
              width: 50px;
              height: 1px;
              background: #1890ff;
              position: absolute;
              transform: translate(-50%, -50%);
              left: 50%;
              bottom: -4px;
              display: none;
            }
          }
          .progress-tabone-select {
            color: #1890ff;
            div {
              display: block;
            }
          }
        }
        .industry-thirdone-title-rdone {
          width: 80px;
          height: 24px;
          line-height: 24px;
          background: #ffffff;
          border: 1px solid #e1e1e1;
          font-size: 12px;
          color: #999999;
          text-align: center;
        }
        .industry-thirdone-title-rselect {
          color: #ffffff;
          background: #1890ff;
          border: 1px solid #1890ff;
        }
      }
    }
    .linesort {
      right: 40px;
      top: 20px;
      position: absolute;
      z-index: 9;
      cursor: pointer;
      img {
        width: 21px;
        height: 19px;
      }
    }
  }
}
.rates-one {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .rates-one-l {
    width: 63.5%;
    height: 650px;
    background: #ffffff;
    padding: 20px;
    overflow: hidden;
    position: relative;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
    .rates-onemap-con {
      // position: relative;
      .legend {
        position: absolute;
        bottom: 56px;
        left: 20px;
        .legendone {
          display: flex;
          align-items: center;
          margin-top: 8px;
          .block {
            width: 30px;
            height: 20px;
            margin-right: 14px;
          }
          .text {
            font-size: 20px;
            color: #666666;
          }
        }
      }
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #28282c;
        position: absolute;
        bottom: 60px;
        left: 70%;
      }
      .map {
        margin: 0 auto;
      }
    }
  }
  .rates-one-r {
    width: 35%;
    height: 650px;
    background: #ffffff;
    padding: 20px;
    .industry-thirdone-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .industry-thirdone-title-l {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        .line {
          width: 4px;
          height: 16px;
          background: #1890ff;
          border-radius: 2px;
        }
        .title {
          margin-left: 8px;
        }
      }
    }
  }
}
.industry-thirdone-title-l {
  width: 200px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  .line {
    width: 4px;
    height: 16px;
    background: #1890ff;
    border-radius: 2px;
  }
  .title {
    margin-left: 8px;
  }
}
.industry-thirdone-title-r-con {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.industry-thirdone-title-r {
  max-width: 660px;
  display: flex;
  cursor: pointer;
  .industry-progress-tab {
    width: 100%;
    min-width: 360px;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    // margin-top: 4px;
    .industry-progress-tabone {
      position: relative;
      cursor: pointer;
      // margin-left: 34px;
      font-size: 14px;
      text-align: center;
      div {
        width: 50px;
        height: 1px;
        background: #1890ff;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: -4px;
        display: none;
      }
    }
    .progress-tabone-select {
      color: #1890ff;
      div {
        display: block;
      }
    }
  }
  .industry-thirdone-title-rdone {
    width: 80px;
    height: 24px;
    line-height: 24px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    font-size: 12px;
    color: #999999;
    text-align: center;
  }
  .industry-thirdone-title-rselect {
    color: #ffffff;
    background: #1890ff;
    border: 1px solid #1890ff;
  }
}
</style>

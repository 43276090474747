import http from "@/plugins/https";
// 大统计信息
export function getIndustryRates(data) {
  return http({
    url: `/consumIndustry/getAreaPow`,
    params:data,
    method: "GET"
  });
}

export function getIndustryAreaPows(data) {
  return http({
    url: `/consumIndustry/getAreaPows`,
    params:data,
    method: "GET"
  });
}

export function getAreaIndustries(data) {
  return http({
    url: `/consumIndustry/getAreaIndustries`,
    params: data,
    method: "GET"
  });
}